
import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import { whiteArrow } from '../../Assets/Data';
import { toast } from 'react-toastify';
import config from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TOAST_CONFIG } from "../../lib/constants";

export default function EmailCommunity() {
  const navigate = useNavigate();
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [notify, setNotify] = useState([]);
    const [communityLoading, setCommunityLoading] = useState(false);

    const initialCommunityState = {
        email_address: "",
        term_condition: false,
      };
    
      const [communityData, setCommunityData] = useState(initialCommunityState);

      const validationObj = {
        hasErr: false,
        email_address: {
          error: false,
          msg: "",
        },
        term_condition: {
            error: false,
            msg: "",
          },
      };
    
      const [validationErrors, setValidationErrors] = useState({
        ...validationObj,
      });

      const handleValidation = (formInput) => {
        formInput = formInput || communityData;
        let validationerr = { ...validationObj };
    
        if (formInput.email_address == "") {
          validationerr.hasErr = true;
          validationerr.email_address = {
            error: true,
            msg: "Email Address is required",
          };
        }
          if (formInput.term_condition == false) {
            validationerr.hasErr = true;
            validationerr.term_condition = {
              error: true,
              msg: "Term condition is required",
            };
          }
          
    
        setValidationErrors(validationerr);
        if (validationerr.hasErr) {
          return false;
        }
        return true;
      };
    
      const setFormData = (e) => {
        let newState = {
          ...communityData,
          [e.target.name]: e.target.value,
        };
        setCommunityData(newState);
        if (validationErrors.hasErr) {
          handleValidation(newState);
        }
      };

      const handleTermSetting = (e) => {
        setCheck(!check);
        setCommunityData({
            ...communityData,
              term_condition: !check,
          });
      }

      let saveCommunity = async () => {
        if (!handleValidation()) {
          toast.error("Correct form data!",TOAST_CONFIG);
          return false;
        }
        console.log("Final Submit @@ = ",communityData);
        if (
            communityData.email_address !== "" &&
            communityData.term_condition !== ""
        ) {
        setCommunityLoading(true);
        axios
          .post(`${BACKEND_URL}/api/V1/community`, communityData)
          .then((response) => {
            console.log("response @ = ",response.data);
              if (response.data.status) {
                  toast.success(response.data.result.message, TOAST_CONFIG);
                  setCheck(!check);
                  setCommunityLoading(false);
                  setCommunityData(initialCommunityState);
              } else {
                  toast.error(response.data.errors, TOAST_CONFIG);
              }
          })
          .catch((error) => {
              
              toast.error("Error fetching data", TOAST_CONFIG);
              console.error("Error fetching data:", error);
          });
        }
      };


  // return (
  //   <Box className="mailSection">
  //       <Typography className="ambHeader">
  //         Join our <span className="TimesNowText">community</span>
  //       </Typography>
  //       <Typography className="summery14">
  //         Sign up and stay up to date on product launches and pre order. By
  //         entering your email address you agree to our Terms and Conditions and
  //         Privacy and Cookie Policy.{" "}
  //       </Typography>

  //       <Box className="mailInputBox">
  //         <input type="email" placeholder="Your email*" name="email_address"
  //                     value={communityData.email_address ? communityData.email_address : "" }
  //                     onChange={setFormData} />
                      
  //         <Box className="submitBtn" onClick={() =>saveCommunity()} disabled={communityLoading}>{communityLoading ? "Saving..." : whiteArrow}</Box>
  //       </Box>
  //       <span className="validationerr">{validationErrors.email_address.msg}</span>
  //       <Box className="MailCheckBox">
  //         <Box
  //           className={check ? "check checkActive" : "check"}
  //           onClick={(e) => handleTermSetting(e)}
  //         ></Box>
  //         <Typography>Please click here to accept our</Typography>
  //         <Box className="termsbox" onClick={() => navigate("/terms-and-conditions")}>
  //           <Typography>Terms and Conditions</Typography>
  //           <Box></Box>
  //         </Box>
          
  //       </Box>
  //       <span className="validationerr">{validationErrors.term_condition.msg}</span>
  //     </Box>
  // )
  return null;
}
