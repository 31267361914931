import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
//components
import CookiePop from "../../Components/CookiePop";
import { Box, Typography } from "@mui/material";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import { whiteArrow } from "../../Assets/Data";

//images
import GarethImg1 from "../../Assets/Images/Gareth-WardImg1.jpg";
import GarethImg2 from "../../Assets/Images/Eleonora_Boscarelli_photography_1746-scaled.jpg";
import GarethImg3 from "../../Assets/Images/DSC_1775-2-scaled.jpg";
import GarethImg4 from "../../Assets/Images/DSC_1578-scaled-e1718127521660-300x289.jpg";
import GarethImg5 from "../../Assets/Images/Main-house-exterior-pc-Heather-Birnie-1024x683.jpg";
import { getCountryCode } from "../../services/countryService";
import config from "../../config";
import axios from "axios";
import EmailCommunity from "../../Components/EmailCommunity";

export default function BlogDetail({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const { blogId } = useParams();
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [blogDetail, setblogDetail] = useState([]);
    const [simplecontent, setsimplecontent] = useState("");

    useEffect(() => {
        const countryCode = getCountryCode();
        axios
            .get(`${BACKEND_URL}/api/V1/blog-detail`, {
                params: { country: countryCode, blogId: blogId }
            })
            .then((response) => {
                console.log("blog content @@ =", response.data.result.blogs[0]);
                setblogDetail(response.data.result.blogs[0]);
                const reactElements = parse(response.data.result.blogs[0]?.content_3);
                setsimplecontent(reactElements)
            })
            .catch((error) => {
                console.error("Error fetching layout data:", error);
            });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="garethPage">
                <Box className="garethFBox">
                    <Box className="grTextBox">
                        <Typography className="grHeaderText" data-aos="fade-up">
                            {blogDetail?.title}
                        </Typography>
                        <Typography className="grNText" data-aos="fade-up">
                            {blogDetail?.short_description}
                        </Typography>
                        <Box className="shopSLabelTextBox" data-aos="fade-up">
                            <span data-aos="fade-up" onClick={() => navigate(`${blogDetail?.button_link_1}`)}>{blogDetail.button_label_1}</span>
                            <span className="ssltbLine" onClick={() => navigate(`${blogDetail?.button_link_1}`)}></span>
                        </Box>
                    </Box>
                    <Box className="grImgBox">
                        <img src={`${BACKEND_URL}${blogDetail.story_image_1}`} />
                    </Box>
                </Box>

                <Box className="garethSBox">
                    <Box className="grsImgBox">
                        <img src={`${BACKEND_URL}${blogDetail.image}`} />
                    </Box>
                    <Box className="grsTextBox">
                        <Box className="grSB1">
                            {/* <img src={GarethImg3} /> */}
                        </Box>
                        <Box className="grSB2">
                            <Typography className="grsb1TopText" data-aos="fade-up">
                                "N25 Kaluga Caviar is my favourite, can not beat it in my
                                opinion."
                            </Typography>
                            <img src={`${BACKEND_URL}${blogDetail.story_image_2}`} />
                            {simplecontent}
                            {/* <Typography className="grsb1BoldText" data-aos="fade-up">
                How would you describe your food philosophy?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                My food philosophy is fun and flavour, that’s what it’s all
                about. We’re very ingredient-led here.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                What do you love about caviar?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                It’s just f**king amazing, delicious, right up my street.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                Which N25 Caviar is your favourite?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                N25 Kaluga caviar, can not beat it in my opinion.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                What’s your favourite way to enjoy caviar?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                Taking the tin lid off and sticking my tongue right in
                there!...Or on a beautiful bit of sourdough bread.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                One thing that is always in your kitchen?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                Me!...No, Patrick McNutty my Head Chef! He’s always in the
                kitchen, the guy doesn’t stop
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                What’s on the top of your bucket list?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                To retire and live out in Ibiza one day
              </Typography> */}
                        </Box>
                    </Box>
                </Box>

                {/* <Box className="garethTBox">
          <img src={GarethImg5} />

          <span className="discoverBtn">
            <Box className="shopSLabelTextBox">
              <span>Shop Gareth's Choice </span>
              <span className="ssltbLine"></span>
            </Box>
            <span className="dbsLine dbTops"></span>
            <span className="dbsLine dbRighs"></span>
            <span className="dbsLine dbBottoms"></span>
            <span className="dbsLine dbLefts"></span>
          </span>
        </Box> */}

                {/* mail section */}
            </Box>
            <EmailCommunity />
            <Footer />
        </>
    );
}
