import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';

import config from "../../config";
import axios from "axios";
import "./style.css";
import EmailCommunity from '../../Components/EmailCommunity';
import { getCountryCode } from '../../services/countryService';

export default function ShippingInformationPage({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const [firstSection, setFirstSection] = useState([]);
    const BACKEND_URL = config.BACKEND_URL;
    const [simplecontent, setsimplecontent] = useState("");
    useEffect(() => {
        const countryCode = getCountryCode();
        axios
        .get(
            `${BACKEND_URL}/api/V1/shipping-return-policy`,{
            params: { country: countryCode }
        })
        .then((response) => {
            setFirstSection(response.data.result.content);
            const reactElements = parse(response.data.result?.content?.content);
            setsimplecontent(reactElements)
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="caviarListPage">
                <Box className='productHeaderBox shipping-page'>
                    <Typography className='ambHeader' data-aos="fade-up">{firstSection?.title}</Typography>
                    {simplecontent}
                </Box>


                {/* mail section */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
