import React, { useEffect, useState  } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { toast } from "react-toastify";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import crossIcon from "../../Assets/Images/crossIcon.png";
import cancelIcon from "../../Assets/Images/cancelIcon2.jpg";
import EmailCommunity from '../../Components/EmailCommunity';
import { TOAST_CONFIG } from "../../lib/constants";
import {
    TextField,
    Button,
    MenuItem,
    Modal,
    Checkbox,
    Select,
    IconButton,
    Hidden
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';
import { TimeClock } from '@mui/x-date-pickers';
import { getCountryCode } from '../../services/countryService';

export default function GuestPayment({ cookiePop, setCookiePop }) {
    // const userSession = localStorage.getItem("User");
    const BACKEND_URL = config.BACKEND_URL;
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [Fcheck, setFCheck] = useState(false);
    const [Scheck, setSCheck] = useState(false);
    const [cartItem, setCartItem] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [totalCartItem, setTotalCartItem] = useState();
    const [paymentError, setPaymentError] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    // const [couponCode, setCouponCode] = useState("");
    // const [isCouponProcessing, setIsCouponProcessing] = useState(false);
    // const [borderColor, setBorderColor] = useState("1px solid #ffffff80");
    // const [applyCoupon, setapplyCoupon] = useState(false);
    const [shippingAddress, setShippingAddress] = useState(false);
    const [discountAmt, setDiscountAmt] = useState();
    const [countries, setCountry] = useState([]);
    const [cities, setCities] = useState([]);
    const [shippingAddressVld, setShippingAddressVld] = useState(false);
    const [guestCartItem, setGuestCartItem] = useState([]);
    const initialShippingAddressState = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        address: "",
        country_id: "",
        city_id: "",
        postal_code: "",
    };
    const [shippingAddressformData, setShippingAddressFormData] = useState(initialShippingAddressState);
    const initialGuestShippingDetails = {
        shipping_option : "ShipToAddress",
        shipping_address : null,
    }
    const [guestShippingDetails,setGuestShippingDetails] = useState(initialGuestShippingDetails);
    useEffect(() => {
        axios.get(`${BACKEND_URL}/api/V1/get-countries`)
        .then((response) => {
            setCountry(response.data.result.countries);
        })
        .catch((error) => {
            toast.error("Error fetching layout data", TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
    },[]);

    useEffect(() => {        
        const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
        const totalPrice = calculateTotalPrice(guestCart);
        setTotalAmount(totalPrice);
        setGuestCartItem(guestCart);
        setTotalCartItem(guestCart?.length);
    }, [totalAmount, guestCartItem, totalCartItem]);

    const redirectShopping = () => {
        navigate("/all-products");
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // if (userSession) {
            // const User5 = JSON.parse(userSession);
            const countryCode = getCountryCode();
            if (!stripe || !elements) {
                return;
            }
            setIsProcessing(true);
            try {
                const { token, error } = await stripe.createToken(elements.getElement(CardElement));
                const payload = {
                    type: "stripe",
                    token: token,
                    totalAmount: totalAmount,
                    couponCode: "",
                    discountAmt: 0,
                    shipping_details : guestShippingDetails,
                    cart_item : guestCartItem,
                    country_id : countryCode
                }
                if (error) {
                    console.error(error);
                    setPaymentError(error.message);
                    setIsProcessing(false); // Re-enable button
                } else {
                    axios
                    .post(
                        `${BACKEND_URL}/api/V1/guest-payment-process`,
                        payload
                    )
                    .then((response) => {
                        if (response.data.status === true) {
                            toast.success(response.data.result.message, TOAST_CONFIG);
                            sessionStorage.removeItem("guestCart");
                            navigate(`/order-success/${response.data.result.data.orderId}`);
                        }else{
                            setPaymentError(response.data.errors);
                            toast(response.data.errors, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                onClose: () => {
                                    navigate("/payment-failed");
                                    setIsProcessing(false); // Re-enable button
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        setPaymentError(error);
                        toast(error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            onClose: () => {
                                navigate("/payment-failed");
                                setIsProcessing(false); // Re-enable button
                            },
                        });
                        console.error("Error fetching layout data:", error);
                    });
                }
            } catch (error) {
                console.error(error);
                setPaymentError(error.message);
                setIsProcessing(false); // Re-enable button
            }
        // }
    }

    const DARK_CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {
                // backgroundColor: "rgb(31 41 55)",
                iconColor: "#6D28D9",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",

                ":-webkit-autofill": {
                    color: "#fce883",
                },
                "::placeholder": {
                    color: "#D1D5DB",
                },
            },
            invalid: {
                iconColor: "#ef2961",
                color: "#ef2961",
            },
        },
    };
    
    const handleChange = (e) => {
        let newState = {
            ...shippingAddressformData,
            [e.target.name]: e.target.value,
        };
        setShippingAddressFormData(newState);

        let shippingAddressState = {
            ...guestShippingDetails,
            shipping_address : newState
        }
        setGuestShippingDetails(shippingAddressState)
        
        if(e.target.name =="country_id" ){
            getCities(e.target.value)
        }
        if (validationErrors.hasErr) {
            handleValidation(newState);
        }
    };

    const getCities = (id) => {
        axios
        .get(`${BACKEND_URL}/api/V1/get-country-cities?country_id=${id}`)
        .then((response) => {
            if(response.data.status){
                setCities(response.data.result.cities);
            }else{
                toast.error(response.data.message, TOAST_CONFIG);
            }
        })
        .catch((error) => {
            toast.error("Error fetching data", TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
    }

    const validationObj = {
        hasErr: false,
        first_name: {
            error: false,
            msg: "",
        },
        last_name: {
            error: false,
            msg: "",
        },
        phone: {
            error: false,
            msg: "",
        },
        email: {
            error: false,
            msg: "",
        },
        address: {
            error: false,
            msg: "",
        },
        country_id: {
            error: false,
            msg: "",
        },
        city_id: {
            error: false,
            msg: "",
        },
        postal_code: {
            error: false,
            msg: "",
        }
    };
    const [validationErrors, setValidationErrors] = useState({
        ...validationObj,
    });
    const handleValidation = (formInput) => {
        formInput = formInput || shippingAddressformData;
        let validationerr = { ...validationObj };
    
        if (formInput.first_name == "") {
            validationerr.hasErr = true;
            validationerr.first_name = {
                error: true,
                msg: "First name field is required",
            };
        }
        if (formInput.last_name == "") {
            validationerr.hasErr = true;
            validationerr.last_name = {
                error: true,
                msg: "Last name field is required",
            };
        }
        if (formInput.phone == "") {
            validationerr.hasErr = true;
            validationerr.phone = {
                error: true,
                msg: "Phone field is required",
            };
        }else if (!/^\d+$/.test(formInput.phone)) {
            // Ensure that phone number contains only digits
            validationerr.hasErr = true;
            validationerr.phone = {
                error: true,
                msg: "Please enter a valid phone number (only digits)",
            };
        }
        if (formInput.email == "") {
            validationerr.hasErr = true;
            validationerr.email = {
                error: true,
                msg: "Email field is required",
            };
        }
        else if (!/\S+@\S+\.\S+/.test(formInput.email)) {
            // Basic email format validation regex
            validationerr.hasErr = true;
            validationerr.email = {
                error: true,
                msg: "Please enter a valid email address",
            };
        } 
        if (formInput.address == "") {
            validationerr.hasErr = true;
            validationerr.address = {
                error: true,
                msg: "Address line field is required",
            };
        }
        if (formInput.country_id == "") {
            validationerr.hasErr = true;
            validationerr.country_id = {
                error: true,
                msg: "First Name is required",
            };
        }else if (!/^\d+$/.test(formInput.country_id)) {
            // Ensure that phone number contains only digits
            validationerr.hasErr = true;
            validationerr.country_id = {
                error: true,
                msg: "Please select a valid country",
            };
        }
        if (formInput.city_id == "") {
            validationerr.hasErr = true;
            validationerr.city_id = {
                error: true,
                msg: "First Name is required",
            };
        }else if (!/^\d+$/.test(formInput.city_id)) {
            // Ensure that phone number contains only digits
            validationerr.hasErr = true;
            validationerr.city_id = {
                error: true,
                msg: "Please select a valid city",
            };
        }
        if (formInput.postal_code == "") {
            validationerr.hasErr = true;
            validationerr.postal_code = {
                error: true,
                msg: "Postlcode field is required",
            };
        }else if (!/^\d+$/.test(formInput.postal_code)) {
            // Ensure that phone number contains only digits
            validationerr.hasErr = true;
            validationerr.postal_code = {
                error: true,
                msg: "Please enter a valid postlcode (only digits)",
            };
        }
        setValidationErrors(validationerr);
        if (validationerr.hasErr) {            
            return false;
        }
        return true;
    };

    let handleShippingAddresssSubmit = async () => {
        if (!handleValidation()) {
            toast.error("Correct form data!", TOAST_CONFIG);
            setShippingAddressVld(false)
            return false;
        }
        if(shippingAddressformData.first_name !== "" && shippingAddressformData.last_name !== "" && shippingAddressformData.phone !== "" && shippingAddressformData.email !== "" && shippingAddressformData.address !== "" && shippingAddressformData.postal_code !== "" && shippingAddressformData.country_id !== "" && shippingAddressformData.city_id !== ""){
            setShippingAddressVld(true)
            toast.success("The shipping address has been saved.", TOAST_CONFIG);
        }else{
            toast.error("The form all field are required", TOAST_CONFIG);
            setShippingAddressVld(false)
        }
    };

    const [deliveryOptions, setDeliveryOptions] = useState("ShipToAddress")
    const  changeDeliveryOption = (option) =>{
        setShippingAddressVld(false);
        setDeliveryOptions(option)

        let shippingAddressState = {
            ...guestShippingDetails,
            shipping_option : option
        }
        setGuestShippingDetails(shippingAddressState)
    }
    // ==================================================== PACK IT MYSEFLT
    
    const initialPickupAddressState = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        date : null,
        time : null
    };
    const [pickupAddressformData, setPickupAddressFormData] = useState(initialPickupAddressState);
    const pickupValidationObj = {
        hasErr: false,
        first_name: {
            error: false,
            msg: "",
        },
        last_name: {
            error: false,
            msg: "",
        },
        phone: {
            error: false,
            msg: "",
        },
        email: {
            error: false,
            msg: "",
        },
    };
    const [pickupValidationErrors, setPickupValidationErrors] = useState({
        ...pickupValidationObj,
    });
    const pickupHandleValidation = (formInput) => {
        formInput = formInput || pickupAddressformData;
        let validationerr = { ...pickupValidationObj };
    
        if (formInput.first_name == "") {
            validationerr.hasErr = true;
            validationerr.first_name = {
                error: true,
                msg: "First name field is required",
            };
        }
        if (formInput.last_name == "") {
            validationerr.hasErr = true;
            validationerr.last_name = {
                error: true,
                msg: "Last name field is required",
            };
            setShippingAddressVld(false)
        }
        if (formInput.phone == "") {
            validationerr.hasErr = true;
            validationerr.phone = {
                error: true,
                msg: "Phone field is required",
            };
        }else if (!/^\d+$/.test(formInput.phone)) {
            // Ensure that phone number contains only digits
            validationerr.hasErr = true;
            validationerr.phone = {
                error: true,
                msg: "Please enter a valid phone number (only digits)",
            };
        }
        if (formInput.email == "") {
            validationerr.hasErr = true;
            validationerr.email = {
                error: true,
                msg: "Email field is required",
            };
        }
        else if (!/\S+@\S+\.\S+/.test(formInput.email)) {
            // Basic email format validation regex
            validationerr.hasErr = true;
            validationerr.email = {
                error: true,
                msg: "Please enter a valid email address",
            };
        }
        setPickupValidationErrors(validationerr);
        if (validationerr.hasErr) {            
            return false;
        }
        return true;
    };
    const handlePickupChange = (e) => {
        let newState = {
            ...pickupAddressformData,
            [e.target.name]: e.target.value,
        };
        setPickupAddressFormData(newState);

        let shippingAddressState = {
            ...guestShippingDetails,
            shipping_address : newState
        }
        setGuestShippingDetails(shippingAddressState)

        if (pickupValidationErrors.hasErr) {
            pickupHandleValidation(newState);
        }
    };
    const handlePickupChangeDate = (newValue) => {
        let newState = {
            ...pickupAddressformData,
            date : newValue,
        };
        setPickupAddressFormData(newState);
    };
    const handlePickupChangeTime = (newValue) => {
        const formattedTime = dayjs(newValue).format("HH:mm");
        let newState = {
            ...pickupAddressformData,
            time : formattedTime,
        };
        setPickupAddressFormData(newState);
    };
    let handlePickupAddresssSubmit = async () => {
        if (!pickupHandleValidation()) {
            toast.error("Correct form data!", TOAST_CONFIG);
            setShippingAddressVld(false)
            return false;
        }
        if(pickupAddressformData.first_name !== "" && pickupAddressformData.last_name !== "" && pickupAddressformData.phone !== "" && pickupAddressformData.email !== "" && pickupAddressformData.date !== "" && pickupAddressformData.time !== ""){
            setShippingAddressVld(true)
            let shippingAddressState = {
                shipping_option : deliveryOptions,
                shipping_address : pickupAddressformData
            }
            console.log('pickupAddressformData ##',shippingAddressState);
            setGuestShippingDetails(shippingAddressState)
            toast.success("The shipping address has been saved.", TOAST_CONFIG);
        }else{
            setShippingAddressVld(false)
            toast.error("The form all field are required", TOAST_CONFIG);
        }
    };
    // ==================================================== PACK IT MYSEFLT
    // =========================================== GUEST USER =================================================
        const incrementQty = (variationId, currentQty, price) => {
            var qty = currentQty + 1;
            var productTotalPrice = price * qty;
            updateItemQuantity(variationId, qty, productTotalPrice);
            const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
            const totalPrice = calculateTotalPrice(guestCart);
            setTotalAmount(totalPrice);
        };
        
        const decrementQty = (variationId, currentQty, price) => {
            let qty = currentQty;
            if (qty === 1) {
                var productTotalPrice = price;
                updateItemQuantity(variationId, qty, productTotalPrice);
                const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
                const totalPrice = calculateTotalPrice(guestCart);
                setTotalAmount(totalPrice);
                return qty;
            } else {
                qty = qty - 1;
            }
            var productTotalPrice = price * qty;
            updateItemQuantity(variationId, qty, productTotalPrice);
            const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
            const totalPrice = calculateTotalPrice(guestCart);
            setTotalAmount(totalPrice);
        };
        const updateItemQuantity = (variationId, newQty, productTotalPrice) => {
            setGuestCartItem((prevItems) => {
                const newCart = prevItems.map((item) => 
                    item.variation.id === variationId 
                        ? { ...item, qty: newQty, variation: { ...item.variation, price: productTotalPrice } } 
                        : item
                )
                sessionStorage.setItem("guestCart",JSON.stringify(newCart));
                return newCart;
            });            
        };
        const removeProduct = (variationId) => {
            setGuestCartItem((prevItems) => {
                const newCart = prevItems.filter((item) => item.variation.id !== variationId);
                sessionStorage.setItem("guestCart",JSON.stringify(newCart));
                return newCart;
            });
        };
        const calculateTotalPrice = (items) => {
            if(items !== null){
                return items.reduce((total, item) => {
                    return total + parseFloat(item.variation.price);
                }, 0);
            }else{
                return 0;
            }
        };
    // =========================================== GUEST USER =================================================
    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
                <Box className="contactInputSection">                    
                    <Box className="contactInfo cartInnerBox">
                        <Box className="cartNav">
                            {/*<Box className="cartCrossBtn" onClick={() => setCartPop(false)}>
                                <img src={crossIcon} />
                            </Box>*/}
                            {/* <Typography style={{color:"#fff"}}>Cart {totalCartItem}</Typography> */}
                        </Box>
                        <Box className="cartListBox cart-list-box">
                            {guestCartItem !== null ? (
                                <>
                                    {guestCartItem.map((item) => {
                                        return(
                                            <Box className="productItem">
                                                <Box sx={{background:"none"}} className="cProductImg">
                                                    <img src={`${BACKEND_URL}${item.image}`} />
                                                </Box>
                                                <Box className="productItemTextBox">
                                                    <Typography className='paymentProductTitle'>{item.name} - {item.variation.name}</Typography>
                                                    <Box className="productBtnBox">
                                                        <Typography className='paymentProductTitle'>£ {item.variation.price}</Typography>
                                                        <Box sx={{backgroundColor:"#0e0e0e !important"}} className="inputNumber">
                                                            <button style={{color:"#fff",backgroundColor:"#0e0e0e"}} type="button" onClick={() => decrementQty(item.variation.id,item.qty, item.price)} className='paymentButton'>
                                                                -
                                                            </button>
                                                            <input style={{color:"#fff",backgroundColor:"#0e0e0e"}} type="text" name="" id="" value={item.qty || 1} />
                                                            <button style={{color:"#fff",backgroundColor:"#0e0e0e"}} type="button" onClick={() => incrementQty(item.variation.id,item.qty, item.price)} className='paymentButton'>
                                                                +
                                                            </button>
                                                        </Box>
                                                        <Box style={{ backgroundColor: '#0e0e0e'}} className="cartCrossBtn paymentButton" onClick={() => removeProduct(item.variation.id)}>
                                                            <img src={cancelIcon} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </>
                            ):(                                
                                <Box className="emptyCartMessage">
                                    <Typography sx={{ color: "#fffc", padding: "40px" }}>Your cart is empty. Start shopping now!</Typography>
                                    <Box className="cardFBtnBox">
                                        <span className="discoverBtn cfBlackBtn" onClick={() => redirectShopping()}>
                                            <Typography>Continue Shopping</Typography>
                                            <span className="dbsLine dbTops"></span>
                                            <span className="dbsLine dbRighs"></span>
                                            <span className="dbsLine dbBottoms"></span>
                                            <span className="dbsLine dbLefts"></span>
                                        </span>
                                    </Box>
                                </Box>
                            )
                            }
                        </Box>
                        <Box onClick={() => setShippingAddress(!shippingAddress)} sx={{cursor:"pointer", height : "60px"}} style={{ borderTop : "1px solid #cccccc" }}>
                            <Typography style={{paddingBottom:"16px", paddingTop : "20px"}}>Shipping Address</Typography>
                        </Box>
                        <Box style={{display: shippingAddress ? "flex" : "none", display : "unset"}}>
                            <div class="delivery-options">
                                <div className={ deliveryOptions === "ShipToAddress" ? "option selected" : "option" } onClick={() => changeDeliveryOption("ShipToAddress")}>
                                    <div class="icon">
                                        <img src="https://via.placeholder.com/100" alt="Ship to address" />
                                    </div>
                                    <p>Ship to address</p>
                                </div>
                                <div className={ deliveryOptions === "PickUpMyself" ? "option selected" : "option" }  onClick={() => changeDeliveryOption("PickUpMyself")}>
                                    <div class="icon">
                                        <img src="https://via.placeholder.com/100" alt="Pick up myself" />
                                    </div>
                                    <p>I'll pick it up myself</p>
                                </div>
                            </div>
                            <div className="couponCodeForm" style={{ display : deliveryOptions === "ShipToAddress" ? "block" : "none" }}>
                                <div className='form-div' style={{ paddingLeft: "unset", width : "100%" }}>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <TextField label="First name" name="first_name" value={shippingAddressformData?.first_name || ""} fullWidth onChange={handleChange} />
                                            <span className="validationerr">{validationErrors.first_name.msg}</span>
                                        </div>
                                        <div style={{ width : "50%"}}>
                                            <TextField label="Last name" name="last_name" value={shippingAddressformData?.last_name || ""} fullWidth onChange={handleChange} />
                                            <span className="validationerr">{validationErrors.last_name.msg}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <TextField label="Phone" name="phone" value={shippingAddressformData?.phone || ""} fullWidth onChange={handleChange} />
                                            <span className="validationerr">{validationErrors.phone.msg}</span>
                                        </div>
                                        <div style={{ width : "50%"}}>
                                            <TextField label="Email" name="email" value={shippingAddressformData.email || ""} fullWidth onChange={handleChange} />
                                            <span className="validationerr">{validationErrors.email.msg}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <TextField label="Address line one" name="address" value={shippingAddressformData.address || ""} fullWidth onChange={handleChange} />
                                            <span className="validationerr">{validationErrors.address.msg}</span>
                                        </div>
                                        <div style={{ width : "50%"}}>
                                            <TextField label="Post Code" name="postal_code" value={shippingAddressformData.postal_code || ""} fullWidth onChange={handleChange} />
                                            <span className="validationerr">{validationErrors.postal_code.msg}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }} onChange={handleChange}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <TextField label="Country" name="country_id" value={shippingAddressformData.country_id} select fullWidth onChange={handleChange}>
                                                {countries.map((countrie, index) => (
                                                    <MenuItem color="primary" value={countrie.id}>{countrie.name}</MenuItem>
                                                ))}
                                            </TextField>
                                            <span className="validationerr">{validationErrors.country_id.msg}</span>
                                        </div>
                                        <div style={{ width : "50%" }}>
                                            <TextField label="City" name="city_id" value={shippingAddressformData.city_id} select fullWidth onChange={handleChange}>
                                                {cities.map((citie, index) => (
                                                    <MenuItem color="primary" value={citie.id}>{citie.name}</MenuItem>
                                                ))}
                                            </TextField>
                                            <span className="validationerr">{validationErrors.city_id.msg}</span>
                                        </div>
                                        
                                    </div>
                                    <span className='discoverBtn' style={{ marginBottom : "30px", marginTop : "30px" }} onClick={() =>handleShippingAddresssSubmit()} >
                                        <Typography>Save</Typography>
                                        <span className='dbsLine dbTops'></span>
                                        <span className='dbsLine dbRighs'></span>
                                        <span className='dbsLine dbBottoms'></span>
                                        <span className='dbsLine dbLefts'></span>
                                    </span>
                                </div>
                            </div>
                            <div className="couponCodeForm" style={{ display : deliveryOptions === "PickUpMyself" ? "block" : "none" }}>
                                <div className='form-div' style={{ paddingLeft: "unset", width : "100%" }}>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <TextField label="First name" name="first_name" value={pickupAddressformData?.first_name || ""} fullWidth onChange={handlePickupChange} />
                                            <span className="validationerr">{pickupValidationErrors.first_name.msg}</span>
                                        </div>
                                        <div style={{ width : "50%"}}>
                                            <TextField label="Last name" name="last_name" value={pickupAddressformData?.last_name || ""} fullWidth onChange={handlePickupChange} />
                                            <span className="validationerr">{pickupValidationErrors.last_name.msg}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <TextField label="Phone" name="phone" value={pickupAddressformData?.phone || ""} fullWidth onChange={handlePickupChange} />
                                            <span className="validationerr">{pickupValidationErrors.phone.msg}</span>
                                        </div>
                                        <div style={{ width : "50%"}}>
                                            <TextField label="Email" name="email" value={pickupAddressformData.email || ""} fullWidth onChange={handlePickupChange} />
                                            <span className="validationerr">{pickupValidationErrors.email.msg}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "10px", display : "flex", flexDirection : "row", width : "100%" }}>
                                        <div style={{ width : "50%", paddingRight : "10px"}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                {/* <DateTimeField name="date" label="Select Date" onChange={handlePickupChangeDate} renderInput={(params) => <TextField {...params} />} /> */}
                                                <DatePicker name="date" label="Select Date" onChange={handlePickupChangeDate} renderInput={(params) => <TextField {...params} />} /> 
                                            </LocalizationProvider>
                                        </div>
                                        <div style={{ width : "50%"}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimeField name="time" label="Select Time" format="HH:mm" value={pickupAddressformData.time ? dayjs(pickupAddressformData.time, "HH:mm") : null} onChange={handlePickupChangeTime} renderInput={(params) => <TextField {...params} />} />
                                                {/* <DatePicker name="date" label="Select Date" onChange={handlePickupChangeDate} renderInput={(params) => <TextField {...params} />} />  */}
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <span className='discoverBtn' style={{ marginBottom : "30px", marginTop : "30px" }} onClick={() =>handlePickupAddresssSubmit()} >
                                        <Typography>Save</Typography>
                                        <span className='dbsLine dbTops'></span>
                                        <span className='dbsLine dbRighs'></span>
                                        <span className='dbsLine dbBottoms'></span>
                                        <span className='dbsLine dbLefts'></span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="cartTotalPBox">
                            <Typography className='total-lavel'>Cart total</Typography>
                            <Typography className='total-value'>£ {totalAmount}</Typography>
                        </Box>
                    </Box>
                    <Box className="contactInputBox">
                        <form className={shippingAddressVld ? "StripeForm" : "pointerEvents"} style={{  }} onSubmit={handleSubmit}>
                            <CardElement options={DARK_CARD_OPTIONS} />
                            <button style={{marginTop:"28px",padding:"5px",backgroundColor:"#0e0e0e"}} type="submit" disabled={!stripe || isProcessing} className='paymentButton'>
                                <Typography sx={{color:"#fff"}}>{isProcessing ? "Processing..." : "Pay"}</Typography>
                                <span className='dbsLine dbTops'></span>
                                <span className='dbsLine dbRighs'></span>
                                <span className='dbsLine dbBottoms'></span>
                                <span className='dbsLine dbLefts'></span>
                            </button>
                        </form>
                    </Box>                    
                </Box>
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}