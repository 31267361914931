import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

// images
import productImg1 from "../../Assets/Images/A1.png";
import productImg2 from "../../Assets/Images/A2.png";
import chefSection from "../../Assets/Images/chefSection.jpg";
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";

//components
import RoundLogo from "../../Components/RoundLogo";
import CookiePop from "../../Components/CookiePop";
import { useNavigate } from "react-router-dom";
import NavBar from "../../Components/NavBar";
import VideoWithPlayButton from "../../Components/VideoPlayer";
import Footer from "../../Components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import EmailCommunity from "../../Components/EmailCommunity";
import { getCountryCode } from "../../services/countryService";
import Slider from "../../Components/SliderV2"
gsap.registerPlugin(ScrollTrigger);

export default function Home({ cookiePop, setCookiePop }) {

  const navigate = useNavigate();

  const BACKEND_URL = config.BACKEND_URL;
  const [slug] = useState("home");
  const [pages, setPages] = useState([]); // Define state for pages
  const [firstSection, setFirstSection] = useState([]);
  const [secondSection, setSecondSection] = useState([]);
  const [thirdSection, setThirdSection] = useState([]);
  const [fourthSection, setFourthSection] = useState([]);
  const [fifthSection, setFifthSection] = useState([]);
  const [sixthSection, setSixthSection] = useState([]);
  const [seventhSection, setSeventhSection] = useState([]);
  const [eighthSection, setEighthSection] = useState([]);
  const [ninetheSection, setNinetheSection] = useState([]);
  const [pageHeading, setPageHeading] = useState("");
  const [products, setProducts] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [stories, setStories] = useState([]);
  //   const [firstPart] = pageHeading.split(/(?=\binspires\b)/);

  useEffect(() => {
    const countryCode = getCountryCode();
    console.log("Country Code @@ =", countryCode);
    const BACKEND_URL = config.BACKEND_URL;
    axios
      .get(`${BACKEND_URL}/api/V1/page/${slug}`, {
        params: { country: countryCode }
      })
      .then((response) => {
        const { page } = response.data.result;
        const product = response.data.result.products;
        const people = response.data.result.peoples;
        const storie = response.data.result.stories;

        setPages(page);
        setProducts(product);
        setPeoples(people);
        setStories(storie);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
  }, [slug]);

  useEffect(() => {
    // First section
    const sectionOne = pages?.filter(
      (page) => page.section === "First Section"
    );
    setFirstSection(sectionOne);

    // Second section
    const sectionSecond = pages?.filter(
      (page) => page.section === "Second Section"
    );
    setSecondSection(sectionSecond);

    // Third section
    const sectionThird = pages?.filter(
      (page) => page.section === "Third Section"
    );
    setThirdSection(sectionThird);

    // Fourth section
    const sectionFourth = pages?.filter(
      (page) => page.section === "Fourth Section"
    );
    setFourthSection(sectionFourth);

    const sectionFifth = pages?.filter(
      (page) => page.section === "Fifth Section"
    );
    setFifthSection(sectionFifth);

    // Sixth section
    const sectionSixth = pages?.filter(
      (page) => page.section === "Sixth Section"
    );
    setSixthSection(sectionSixth);
    console.log("Sixth Section @@ =", sectionSixth);
    // Seventh section
    const sectionSeven = pages?.filter(
      (page) => page.section === "Seventh Section"
    );
    setSeventhSection(sectionSeven);

    // Eighth section
    const sectionEighth = pages?.filter(
      (page) => page.section === "Eighth Section"
    );
    setEighthSection(sectionEighth);
    // Nineth section
    const sectionNineth = pages?.filter(
      (page) => page.section === "Ninethe Section"
    );
    setNinetheSection(sectionNineth);
  }, [pages]);

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setVh();
    window.addEventListener("resize", setVh);
    return () => window.removeEventListener("resize", setVh);
  }, []);



  const AmbCard = ({ img, title, subTitle }) => {
    return (
      <Box className="ambCard">
        <img style={{ display: !img ? "none" : "block" }} src={img} />
        <span
          style={{ display: !img ? "none" : "block" }}
          className="discoverBtn"
        >
          <Typography>{title}</Typography>
          <span className="DBLine"></span>
        </span>
        <Typography sx={{ display: !img ? "none" : "block" }}>
          *** {subTitle}
        </Typography>
      </Box>
    );
  };

  const OvCard = ({ img, title, summery, last }) => {
    return (
      <Box className="ovCard" sx={{ paddingRight: last ? "30px" : "0px" }}>
        <Box className="ovCInneerBox">
          <img src={img} />
          <Box className="ovTextBox">
            <Typography className="ovTbHeader">{title}</Typography>
            <Typography className="summery14">{summery}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <RoundLogo />
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />

      {firstSection.map((page) => {
        const [beforeInspires, afterInspires] =
          page.title.split(/(?=\binspires\b)/);

        return (
          <Box key={page.id} className="homeCotainer">
            <img
              style={{ opacity: "0.5" }}
              src={`${BACKEND_URL}${page.first_image}`}
              className="homeBg"
              alt="Home Background"
            />
            <Typography className="homeMheader" data-aos="fade-up">
              {beforeInspires}
              <span className="TimesNowText">{afterInspires}</span>
            </Typography>
          </Box>
        );
      })}
      {secondSection?.map((item) => (
        <Box className="home2ndSection">
          <Box className="home2ndFBox">
            <Box className="h2nImg1">
              <img src={`${BACKEND_URL}${item.first_image}`} />
            </Box>
            <Box className="h2nTextBox">
              <Typography data-aos="fade-up">{item.title}</Typography>
              <span data-aos="fade-up">{item.content}</span>
            </Box>
            <Box className="h2nImg2">
              <img src={`${BACKEND_URL}${item.second_image}`} />
            </Box>
          </Box>
        </Box>
      ))}

      {/* discover Section */}
      {thirdSection?.map((item) => {
        const sentence = item.title;
        const part1 = sentence.substring(0, 9); // "DISCOVER "
        const part2 = sentence.substring(9, 12); // "THE"
        const part3 = sentence.substring(12); // "ALCHEMY OF UMAMI"
        return (
          <Box className="discoverySection">
            <img src={`${BACKEND_URL}${item.first_image}`} className="disBg" />
            <Typography className="dicText1" data-aos="fade-up">
              {item.sub_title}
            </Typography>
            <Typography className="dicText2" data-aos="fade-up">
              {part1}
              <span className="TimesNowText">{part2}</span>
              {part3}
            </Typography>
            <Typography className="dicText3" data-aos="fade-up">
              {item.content}
            </Typography>

            <span className="discoverBtn" data-aos="fade" onClick={() => navigate(`${item.button_link}`)}>
              <Typography>{item.button_label}</Typography>
              <span className="DBLine"></span>
              <span className="dbsLine dbTops"></span>
              <span className="dbsLine dbRighs"></span>
              <span className="dbsLine dbBottoms"></span>
              <span className="dbsLine dbLefts"></span>
            </span>
          </Box>
        );
      })}
      {fourthSection.map((item) => {
        return (
          <Box className="shopSection">
            <Box className="b1"></Box>
            <Box className="b2">
              <Box className="shopTextBox">
                <Typography className="shopTBHeader" data-aos="fade-up">
                  {item.title}
                </Typography>
                <Box className="shopSecondTextBox">
                  <Typography data-aos="fade-up">{item.content}</Typography>
                  <Box
                    className="shopSLabelTextBox"
                    data-aos="fade-up"
                    onClick={() => navigate(`${item.button_link}`)}
                  >
                    <span> {item.button_label} </span>
                    <span className="ssltbLine"></span>
                  </Box>
                </Box>
              </Box>

              <Box className="threeTBox">
                {products.map((product) => {
                  return (
                    <Box
                      className="TTItem"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      <Box className="imageFBox">
                        <img src={`${BACKEND_URL}${product.prod_image}`} className='image1' />
                        <img src={`${BACKEND_URL}${product.prod_cap_image}`} className='image2' />
                        {/* <img src={`${BACKEND_URL}${product.prod_cap_image}`} className='image1' />
                      <img src={`${BACKEND_URL}${product.prod_image}`} className='image2' /> */}
                        {/* <img
                          src={`${BACKEND_URL}${product.prod_image}`}
                          className="image1"
                        /> */}
                        {/*<img src={`${BACKEND_URL}${product.prod_image}`} className="image2" />*/}
                        <Typography>
                          {product.prod_name} <br />
                          from £{product.price}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className="b3"></Box>
          </Box>
        );
      })}
      {/* meticulous Section */}

      {fifthSection?.map((item) => {
        return (
          <Box className="meticulousSection">
            <Box className="metTextBox">
              <Typography style={{ lineHeight: "1.2em" }} className="shopTBHeader" data-aos="fade-up">
                {item.title}
              </Typography>
              <Typography style={{ marginTop: "29px" }} className="metTextSubText" data-aos="fade-up">
                {item.content}
              </Typography>
              <span className="discoverBtn" data-aos="fade" onClick={() => navigate(`${item.button_link}`)}>
                <Typography>{item.button_label}</Typography>
                <span className="DBLine"></span>
              </span>
            </Box>
            <Box className="metImgBox">
              <img src={`${BACKEND_URL}${item.first_image}`} />
            </Box>
          </Box>
        );
      })}

      {/* Ambassadors section */}
      {sixthSection?.map((item) => {
        const [beforeAmbassadors, afterAmbassadors] =
          item.title.split(/(?=\Ambassadors\b)/);
        return (
          <Box className="AmbassadorsSection">
            <Typography style={{ textTransform: 'uppercase' }} className="ambHeader" data-aos="fade-right">
              {beforeAmbassadors}
              <br />
              <span className="TimesNowText">{afterAmbassadors}</span>
            </Typography>
            <Box className="ambCardBox">
              {peoples?.map((peopleitem) => {
                const { img } = peopleitem.image;
                return (
                  <Box className="ambCard">
                    <img src={`${BACKEND_URL}${peopleitem.image}`} />
                    <span className="discoverBtn">
                      <Typography>{peopleitem.name}</Typography>
                      <span className="DBLine"></span>
                    </span>
                    <Typography>*** {peopleitem.designation}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}

      {/* food section */}
      {seventhSection?.map((item) => {
        return (
          <Box className="foodSection">
            <Typography style={{ fontSize: '12px !important' }} className="footTitle1" data-aos="fade-up">
              {item.sub_title}
            </Typography>
            <Typography className="footTitle2" data-aos="fade-up">
              {item.title.split(' ').map((word, index) =>
                (word === 'Food' || word === 'Calls' || word === 'Chefs') ? (
                  <span key={index} className="TimesNowText">{word} </span>
                ) : (
                  ' ' + word + ' '
                )
              )}
            </Typography>
            <Typography className="footTitle3" data-aos="fade-up">
              {item.content}
            </Typography>
            <VideoWithPlayButton />
            <Box className="foodBtnBox">
              <span className="discoverBtn" onClick={() => navigate(`${item.button_link}`)}>
                <Typography>{item.button_label}</Typography>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
        );
      })}
      {/* CHEF section */}
      {eighthSection?.map((item) => {
        const [beforeChef, afterChef] =
          item.title.split(/(?=\STORIES\b)/);
        return (
          <Box className="chefSection">
            <Box className="chefTextBox">
              <Typography className="ambHeader">{beforeChef}
                <br />
                <span className="TimesNowText">{afterChef}</span></Typography>
              <Typography className="summery14">{item.content}</Typography>

              <span className="discoverBtn" onClick={() => navigate(`${item.button_link}`)}>
                <Typography>{item.button_label}</Typography>
                <span className="DBLine"></span>
              </span>
            </Box>
            <Box className="chefImgBox">
              <img src={chefSection} />
            </Box>
          </Box>
        );
      })}

      <Box className="sliderMainSection">
        <p className="ambHeader">Our Values</p>
        <p className="summery14">Quality, sustainability and pure culinary pleasure are the foundations of our brand.</p>

        <Box className="sliderBox">
          <Slider />
        </Box>
      </Box>


      {/* mail section */}
      {/* <Box className="mailSection">
        <Typography className="ambHeader">
          Join our <span className="TimesNowText">community</span>
        </Typography>
        <Typography className="summery14">
          Sign up and stay up to date on product launches and pre order. By
          entering your email address you agree to our Terms and Conditions and
          Privacy and Cookie Policy.{" "}
        </Typography>

        <Box className="mailInputBox">
          <input type="email" name="email" placeholder="Your email*" />
          <Box className="submitBtn">{whiteArrow}</Box>
        </Box>

        <Box className="MailCheckBox">
          <Box
            className={check ? "check checkActive" : "check"}
            onClick={() => setCheck(!check)}
          ></Box>
          <Typography>Please click here to accept our</Typography>
          <Box className="termsbox">
            <Typography>Terms and Conditions</Typography>
            <Box></Box>
          </Box>
        </Box>
      </Box> */}
      <EmailCommunity />
      <Footer />
    </>
  );
}
