import React, { useEffect, useState } from 'react';
import config from "../../config";
import axios from "axios";
import "./style.css";
import { useNavigate } from "react-router-dom";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import { UnderLBtn } from "../../Components/AppBtn"
import VideoWithPlayButton from "../../Components/VideoPlayer"
import EmailCommunity from '../../Components/EmailCommunity';
import { getCountryCode } from '../../services/countryService';

//images
import hybridImg from "../../Assets/Images/RB-Vogue-mar.png";
import dropIcon from "../../Assets/Images/dropIcon.png"


export default function N25({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [menu, setMene] = useState(false);
    const [firstSection, setFirstSection] = useState([]);
    const [secondSection, setSecondSection] = useState([]);
    const [thirdSection, setThirdSection] = useState([]);

    const closeMenu = (e) => {
        if (e.target.id === "bottomMenu") {
            setMene(false)
        }
    }

    useEffect(() => {
        if (menu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [menu]);

    useEffect(() => {
        const countryCode = getCountryCode();
        axios
        .get(
            `${BACKEND_URL}/api/V1/n25-content`,{
            params: { country: countryCode }
        })
        .then((response) => {
            setFirstSection(response.data.result?.first_section);
            setSecondSection(response.data.result?.second_section);
            setThirdSection(response.data.result?.third_section);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="n25Page">
                <Box id="bottomMenu" sx={{ display: menu ? "flex" : "none" }} className="bottomMenu" onClick={closeMenu}>
                    <Box className="bmInnerBox">
                        {UnderLBtn({ btnText: "Our Story", active: true })}
                        {UnderLBtn({ btnText: "The Caviar", active: true })}
                        {UnderLBtn({ btnText: "Loyalty Service", active: true })}
                        {UnderLBtn({ btnText: "N25 Global", active: true })}
                        {UnderLBtn({ btnText: "Careers", active: true })}
                    </Box>
                </Box>
                <Box className="nBox1">
                    <Box className="nb1InnerBox nb1HeaderRTextBox">
                        <Typography className='nb1HeaderRText'>The House</Typography>
                        <img src={dropIcon} className='n25DropIcon' style={{ rotate: menu ? "90deg" : "0deg" }} onClick={() => setMene(!menu)} />
                    </Box>
                    <Box className="nb1InnerBox nb1BtnBTab">
                        {/* onClick added */}
                        {UnderLBtn({ btnText: "Our Brand", active: true, link : "/people" })}
                        {UnderLBtn({ btnText: "The Caviar", active: true, link : "/the-caviar" })}
                        {UnderLBtn({ btnText: "Loyalty Service", active: true, link : "/loyalty-rewards" })}
                        {UnderLBtn({ btnText: "N25 Global", active: true, link : "/n25-global" })}
                        {/* {UnderLBtn({ btnText: "Careers", active: true, link: "https://www.linkedin.com/company/n25-caviar", external: true })} */}
                    </Box>
                    <Box className="nb1InnerBox nb1PText">
                        <Typography>{firstSection.title}</Typography>
                        <Box className="gapBox"></Box>
                        <Typography>{firstSection.content}</Typography>
                    </Box>
                </Box>
                <Box className="nBox2">
                    <Box className="nb2ImgBox">
                        <img src={`${BACKEND_URL}${secondSection.first_image}`} />
                        <Typography>{secondSection.title}</Typography>
                    </Box>
                </Box>
                <Box className="nBox3">
                    <Typography>{secondSection.content}</Typography>
                </Box>
                <VideoWithPlayButton url={thirdSection.first_image} />
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
