import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import './style.css';

import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import dropArrow from "../../Assets/Images/arrowDown.png";
import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';
import { getCountryCode } from '../../services/countryService';

export default function OurWines({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const { categorieid } = useParams();
    const [typeDrop, setTypeDrop] = useState(false);
    const [typeDropVal, setTypeDropVal] = useState("Type");
    const [regionDrop, setRegionDrop] = useState(false);
    const [regionDropVal, setRegionDropVal] = useState("Region");
    const [firstSection, setFirstSection] = useState([]);
    const [types, setTypes] = useState([]);
    const [products, setProducts] = useState([]);
    const BACKEND_URL = config.BACKEND_URL;
    const [TypeID, setTypeID] = useState('');
    const [regionId, setRegionId] = useState('');
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        const countryCode = getCountryCode();
        axios
            .get(`${BACKEND_URL}/api/V1/our-wines/content`, {
                params: { country: countryCode, categorie: categorieid }
            })
            .then((response) => {
                console.log("Wine content @@ =", response.data.result);
                setFirstSection(response.data.result.content);
                setTypes(response.data.result.colors);
                setRegions(response.data.result.regions);
                console.log("Region @@ =",regions)
            })
            .catch((error) => {
                console.error("Error fetching layout data:", error);
            });
    }, []);

    useEffect(() => {
        getProducts();
    }, [TypeID]);

    useEffect(() => {
        getProducts();
    }, [regionId]);



    const changeType = (name) => {
        setTypeDropVal(name)
        if (name === 'All') {
            setTypeID('')
        }
        getProducts();
    }

    const changeRegion = (name) => {
        console.log("Change Region @@ = ",name,regionId);
        setRegionDropVal(name)
        if (name === 'All') {
            setRegionId('')
        }
        getProducts();
    }

    const getProducts = async () => {
        const countryCode = getCountryCode();
        axios
            .get(`${BACKEND_URL}/api/V1/our-wines/products`, {
                params: { country: countryCode, categorie: categorieid, color_id: TypeID, region_id: regionId }
            })
            .then((response) => {
                console.log("Caviar Product @@ =", response.data.result);
                setProducts(response.data.result.products);
            })
            .catch((error) => {
                console.error("Error fetching layout data:", error);
            });
    }

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="caviarListPage">
                <Box className='productHeaderBox'>
                    <Typography className='ambHeader' data-aos="fade-up">{firstSection.title}</Typography>
                    <Typography className='productPageSuhHeader' data-aos="fade-up">{firstSection.content}</Typography>

                    <Box className="dropBox typeDrop" onClick={() => setTypeDrop(!typeDrop)}>
                        <Typography>{typeDropVal}</Typography>
                        <img src={dropArrow} />
                        <Box className="dropItemBox" sx={{ height: typeDrop ? "200px" : "0px" }}>
                        <Box className="dropItem" onClick={() => { setTypeID(''); changeType('All'); }}>
                            <Typography>All</Typography>
                        </Box>
                            {types.map((item,j) => {
                                return (
                                    <Box key={j} className="dropItem" onClick={() => { setTypeID(item.id); changeType(item.name); }}>
                                        <Typography>{item.name}</Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>

                    <Box className="dropBox regionDrop" onClick={() => setRegionDrop(!regionDrop)}>
                        <Typography>{regionDropVal}</Typography>
                        <img src={dropArrow} />
                        <Box className="dropItemBox" sx={{ height: regionDrop ? "200px" : "0px" }}>
                        <Box className="dropItem" onClick={() => { setRegionId(''); changeRegion('All'); }}>
                            <Typography>All</Typography>
                        </Box>
                            {regions.map((item, i) => {
                                return (
                                    <Box key={i} className="dropItem" onClick={() => { setRegionId(item.id); changeRegion(item.name); }}>
                                        <Typography>{item.name}</Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ justifyContent: "normal", gap: "20px" }} className="caviarListBox">
                    {
                        products?.map((el, i) => (
                            <Box sx={{ marginTop: "20px" }} className="TTItem winesProducts" key={i} onClick={() => navigate(`/product/${el.id}`)}>
                                <Box className="imageFBox wineProductImages">
                                    <img style={{ position: "absolute", height: "100%", width: "100%" }} src={`${BACKEND_URL}${el.prod_image}`} className='' />

                                </Box>
                                <Typography style={{ marginTop: "7px" }}>{el.prod_name} <br />from {el.price} €</Typography>
                            </Box>
                        ))
                    }
                </Box>

                {/* mail section */}

                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
