import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import productImg1 from "../../Assets/Images/A1.png";
import productImg2 from "../../Assets/Images/A2.png";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';

import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import dropArrow from "../../Assets/Images/arrowDown.png";
import { whiteArrow, caviarList } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';
import { getCountryCode } from '../../services/countryService';

export default function CaviarList({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const { categorieid } = useParams();
    const [check, setCheck] = useState(false);
    const [typeDrop, setTypeDrop] = useState(false);
    const [typeDropVal, setTypeDropVal] = useState("Type");
    const [firstSection, setFirstSection] = useState([]);
    const [types, setTypes] = useState([]);
    const [products, setProducts] = useState([]);
    const BACKEND_URL = config.BACKEND_URL;
    const [TypeID, setTypeID] = useState('');

    useEffect(() => {
        const countryCode = getCountryCode();
        axios
            .get(`${BACKEND_URL}/api/V1/our-caviar/content`, {
                params: { country: countryCode }
            })
            .then((response) => {
                // console.log("Caviar List @@ =",response.data.result);
                setFirstSection(response.data.result.content);
                setTypes(response.data.result.types);
            })
            .catch((error) => {
                console.error("Error fetching layout data:", error);
            });
    }, []);

    useEffect(() => {
        getProducts();
    }, [TypeID]);

    

    const changeType = (name) => {
        setTypeDropVal(name)
        if(name === 'All'){
            setTypeID('')
        }
        getProducts();
    }

    const getProducts = async () => {
        const countryCode = getCountryCode();
        axios
        .get(`${BACKEND_URL}/api/V1/our-caviar/products`, {
            params: { country: countryCode, categorie : categorieid, type : TypeID }
        })
        .then((response) => {
            // console.log("Caviar List @@ =",response.data.result);
            setProducts(response.data.result.products);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="caviarListPage">
                <Box className='productHeaderBox'>
                    <Typography className='ambHeader' data-aos="fade-up">{firstSection.title}</Typography>
                    <Typography className='productPageSuhHeader' data-aos="fade-up">{firstSection.content}</Typography>

                    <Box className="dropBox" onClick={() => setTypeDrop(!typeDrop)}>
                        <Typography>{typeDropVal}</Typography>
                        <img src={dropArrow} />
                        <Box className="dropItemBox" sx={{ height: typeDrop ? "200px" : "0px" }}>
                            {types.map((item) => {
                                return (                                    
                                    <Box className="dropItem" onClick={() => { setTypeID(item.id); changeType(item.name); }}>
                                        <Typography>{item.name}</Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>

                <Box className="caviarListBox">
                    {
                        products?.map((el, i) => (
                            <Box className="TTItem" key={i} onClick={() => navigate(`/product/${el.id}`)}>
                                <Box className="imageFBox">
                                    <img src={`${BACKEND_URL}${el.prod_image}`} className='image1' />
                                    <img src={`${BACKEND_URL}${el.prod_cap_image}`} className='image2' />
                                    <Typography style={{ textTransform: 'uppercase' }}>{el.prod_name}<br />from {el.price} €</Typography>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>

                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                            <Typography>Terms and Conditions</Typography>
                            <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
