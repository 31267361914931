import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import "./style.css";

import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

import { Box, Typography } from "@mui/material";

import MyOrders from "../../Components/MyOrders";
import AccountDetail from "../../Components/AccountDetail";
import PrivacySettings from "../../Components/PrivacySettings";
import EmailPreference from "../../Components/EmailPreference";
import AddressBook from "../../Components/AddressBook";

export default function AccountPage() {
    const userSession = localStorage.getItem("User");
    const [profileMenuTab, setProfileMenuTab] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [accountMenu, setAccountMenu] = useState("My Order");
    const navigate = useNavigate();

    const drValue = searchParams.get('dr');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userSession !== undefined && userSession !== null) {
            const User = JSON.parse(userSession);
          console.log("userSession @@ =",userSession);
        } else {
          navigate("/login");
        }
      }, [userSession]);

      useEffect(() => {
        if (drValue) {
            setProfileMenuTab(drValue);

            switch (drValue) {
                case "0":
                    setAccountMenu("My Orders");
                    break;
                case "1":
                    setAccountMenu("Account Details");
                    break;
                case "2":
                    setAccountMenu("Address Book");
                    break;
                case "3":
                    setAccountMenu("Email Preferences");
                    break;
                case "4":
                    setAccountMenu("Privacy Settings");
                    break;
                default:
                    setAccountMenu("My Orders");
            }

            setSearchParams({ dr: drValue });  // Update the URL with the correct 'dr' value
            setLoading(true);
        }
    }, [drValue, setSearchParams]);

      console.log("Account Tabs @@ = ",drValue,accountMenu,profileMenuTab);

    const handleFRedirection = (redirectLink) => {
        navigate(redirectLink);
    }
    return (
        <Box className="accountContainer accountPage">
            <Box className="navbar">
                <NavBar />
            </Box>
            <Box className="accTopSection">
                <Box className="titles">
                    <Typography className="profileHeaderM">My Account</Typography>
                    <Typography className="profileHeaderL">{accountMenu}</Typography>
                </Box>
            </Box>
            <Box className="accountMenus">
                <Box className="menus">
                    <ul className="menuItems">
                        <li>
                            <Typography
                                className={`menuItem ${
                                accountMenu === "My Orders" ? "selectedMenuItem" : ""
                                }`}
                                onClick={() => {
                                setSearchParams({ dr: 0 });
                                setProfileMenuTab(0);
                                setAccountMenu("My Orders");
                                }}
                            >
                                My Orders
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                className={`menuItem ${
                                accountMenu === "Account Details" ? "selectedMenuItem" : ""
                                }`}
                                onClick={() => {
                                setSearchParams({ dr: 1 });
                                setProfileMenuTab(1);
                                setAccountMenu("Account Details");
                                }}
                            >
                                Account Details
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                className={`menuItem ${
                                accountMenu === "Address Book" ? "selectedMenuItem" : ""
                                }`}
                                onClick={() => {
                                setSearchParams({ dr: 2 });
                                setProfileMenuTab(2);
                                setAccountMenu("Address Book");
                                }}
                            >
                                Address Book
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                className={`menuItem ${
                                accountMenu === "Email Preferences" ? "selectedMenuItem" : ""
                                }`}
                                onClick={() => {
                                setSearchParams({ dr: 3 });
                                setProfileMenuTab(3);
                                setAccountMenu("Email Preferences");
                                }}
                            >
                                Email Preference
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                className={`menuItem ${
                                accountMenu === "Privacy Settings" ? "selectedMenuItem" : ""
                                }`}
                                onClick={() => {
                                setSearchParams({ dr: 4 });
                                setProfileMenuTab(4);
                                setAccountMenu("Privacy Settings");
                                }}
                            >
                                Privacy settings
                            </Typography>
                        </li>

                        <li>
                            <Typography
                                className={`menuItem `}
                                onClick={() => {
                                handleFRedirection("/loyalty-rewards");
                                }}
                            >
                                Reward Page
                            </Typography>
                        </li>

                        <li>
                            <Typography
                                className={`menuItem `}
                                onClick={() => {
                                handleFRedirection("/offer");
                                }}
                            >
                                Offer Page
                            </Typography>
                        </li>
                    </ul>
                </Box>
                {loading && (
                <Box className="individualPage">
                    {profileMenuTab == 0 ? (
                        <MyOrders
                            profileMenuTab={profileMenuTab}
                            setProfileMenuTab={setProfileMenuTab}
                        />
                    ) : profileMenuTab == 1 ? (
                        <AccountDetail
                            profileMenuTab={profileMenuTab}
                            setProfileMenuTab={setProfileMenuTab}
                        />
                    ) : profileMenuTab == 2 ? (
                        <AddressBook
                            profileMenuTab={profileMenuTab}
                            setProfileMenuTab={setProfileMenuTab}
                        />
                    ) : profileMenuTab == 3 ? (
                        <EmailPreference
                            profileMenuTab={profileMenuTab}
                            setProfileMenuTab={setProfileMenuTab}
                        />
                    ) : profileMenuTab == 4 ? (
                        <PrivacySettings
                            profileMenuTab={profileMenuTab}
                            setProfileMenuTab={setProfileMenuTab}
                        />
                    ) : null}
                </Box>
                )}
            </Box>

      <Footer />
    </Box>
  );
}
