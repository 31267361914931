import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CookiePop from "../../Components/CookiePop";
import NavBar from "../../Components/NavBar";
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import EmailCommunity from '../../Components/EmailCommunity';
import { getCountryCode } from '../../services/countryService';
import config from "../../config";
import axios from "axios";
import "./style.css";

export default function OfferPage({ cookiePop, setCookiePop }) {
    const [email, setEmail] = useState('');
    const [check, setCheck] = useState(false);
    const [allOffers, setAllOffers] = useState([]);
    const BACKEND_URL = config.BACKEND_URL;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubscribe = () => {
        // Handle subscription logic
        alert(`Subscribed with email: ${email}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        const BACKEND_URL = config.BACKEND_URL;
        const countryCode = getCountryCode();
        axios
        .get(`${BACKEND_URL}/api/V1/offers`, {
            params: { country: countryCode }
        })
        .then((response) => {
            setAllOffers(response.data.result.offers);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="OfferPage">
                <Box className="offerInfo">
                    <Typography className='textInfo1'>Exclusive Offers</Typography>
                    <Typography className='offerDescription'>
                        Join us and enjoy exclusive offers tailored just for you. Sign up below to stay updated!
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                {allOffers.length > 0 && allOffers.map((offer, index) => (
                    <Box 
                    key={index} 
                    className="offerDetails" 
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="center" 
                    alignItems="center" 
                    m={2}  // margin between the boxes
                    p={2}  // padding inside the box
                    width="30%"  // ensure three boxes fit in a row
                    height="350px"  // fixed height to make boxes same size
                    textAlign="center" // center text within box
                    border="1px solid #ddd" // optional, for a border around the box
                    borderRadius="8px" // optional, rounded corners
                    >
                    {/* Image */}
                    <Box mb={2}>  {/* margin bottom for spacing between image and text */}
                        <img src={`${BACKEND_URL}${offer.offer_image}`} alt={offer?.title} width="100" height="100" />
                    </Box>
                    
                    {/* Offer Title */}
                    <Typography className='detailsHeader' style={{ fontSize: '18px', marginBottom: '8px' }}>
                        {offer?.title}
                    </Typography>

                    {/* Offer Content */}
                    <Typography className='detailsInfo' style={{ fontSize: '14px' }}>
                        {offer?.content}
                    </Typography>
                    </Box>
                ))}
                </Box>

                {/* mail section */}
                <EmailCommunity />
                {/* mail section */}
                
            </Box>
            <Footer />
        </>
    );
}