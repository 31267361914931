import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";
import config from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Box, TextField, Button, MenuItem, Typography } from "@mui/material";
import { TOAST_CONFIG } from "../../lib/constants";

export default function AccountDetail({ profileMenuTab, setProfileMenuTab }) {
    const BACKEND_URL = config.BACKEND_URL;
    const userSession = localStorage.getItem("User");
    const [editingSections, setEditingSections] = useState([false, false, false]);
    const [user, setUserInformation] = useState([]);
    const [passwordSubmited, setPasswordSubmited] = useState(false);
    const [notify, setNotify] = useState([]);
    const [userId, setUserId] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (userSession !== undefined && userSession !== null) {
            console.log("userSession @@ =",userSession);
        } else {
            navigate("/login");
        }
        }, [userSession]);

    useEffect(() => {
        if (userSession) {
            const User = JSON.parse(userSession);
            setUserId(User.id);
        }    
        
    }, [userSession]);

    const toggleEditComponent = (index) => {
        const newEditingSection = [...editingSections];
        newEditingSection[index] = !newEditingSection[index];
        setEditingSections(newEditingSection);
    };
    const [formData, setFormData] = useState({
        user_id : userId,
        first_name: "",
        last_name: "",
        phone : "",
        email: "",
        current_password : "",
        new_password : "",
        confirm_password : ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if(formData.first_name != "" && formData.last_name != "" && formData.phone != ""){
            const postdata = {
                user_id : userId,
                first_name : formData.first_name,
                last_name : formData.last_name,
                phone : formData.phone
            };

            axios
            .post(`${BACKEND_URL}/api/V1/get-profile-information/update`,postdata)
            .then((response) => {
                if(response.data.status){
                    toggleEditComponent(0)
                    getUser(userId)
                    // const notifys = () => toast.success(response.data.message);
                    // setNotify(notifys);
                    toast.success(response.data.message,TOAST_CONFIG);
                }else{
                    // const notifys = () => toast.error(response.data.message);
                    // setNotify(notifys);
                    toast.error(response.data.message,TOAST_CONFIG);
                }
            })
            .catch((error) => {
                // const notifys = () => toast.error("Error fetching layout data");
                // setNotify(notifys);
                toast.error("Error fetching layout data",TOAST_CONFIG);
                console.error("Error fetching layout data:", error);
            });
        }else{
            // const notifys = () => toast.error("All field are required");
            // setNotify(notifys);
            toast.error("All field are required",TOAST_CONFIG);
        }
        // Perform form submission logic here
    };
    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if(formData.email != ""){
            const postdata = {
                user_id : userId,
                email : formData.email,
            };

            axios
            .post(`${BACKEND_URL}/api/V1/user-profile/email/update`,postdata)
            .then((response) => {
                if(response.data.status){
                    toggleEditComponent(1)
                    getUser(userId)
                    // const notifys = () => toast.success(response.data.message);
                    // setNotify(notifys);
                    toast.success(response.data.message,TOAST_CONFIG);
                }else{
                    // const notifys = () => toast.error(response.data.message);
                    // setNotify(notifys);
                    toast.error(response.data.message,TOAST_CONFIG);
                }
            })
            .catch((error) => {
                // const notifys = () => toast.error(error.response.data.message);
                // setNotify(notifys);
                toast.error(error.response.data.message,TOAST_CONFIG);
                console.error("Error fetching layout data:", error);
            });
        }else{
            // const notifys = () => toast.error("Email field id required");
            // setNotify(notifys);
            toast.error("Email field id required",TOAST_CONFIG);
        }
        // Perform form submission logic here
    };

    const handlePasswordChangeSubmit = (e) => {
        setPasswordSubmited(true)
        e.preventDefault();
        if(formData.new_password != formData.confirm_password){
            // const notifys = () => toast.error("Both password are not matched");
            // setNotify(notifys);
            toast.error("Both password are not matched",TOAST_CONFIG);
        }
        if(
            formData.current_password != "" && 
            formData.new_password != "" &&
            formData.confirm_password != "" &&
            formData.new_password === formData.confirm_password
        ){
            
            const postdata = {
                user_id : userId,
                current_password : formData.current_password,
                new_password : formData.new_password,
                confirm_password : formData.confirm_password
            }
            axios
            .post(`${BACKEND_URL}/api/V1/user-profile/change-password`,postdata)
            .then((response) => {
                if(response.data.status){
                    toast.success(response.data.message,TOAST_CONFIG);
                    toggleEditComponent(2)
                }else{
                    // const notifys = () => toast.error(response.data.message);
                    // setNotify(notifys);
                    toast.error(response.data.message,TOAST_CONFIG);
                }
            })
            .catch((error) => {
                // const notifys = () => toast.error(error.response.data.message);
                // setNotify(notifys);
                toast.error(error.response.data.message,TOAST_CONFIG);
                console.error("Error fetching layout data:", error);
            });
        }else{
            if(formData.new_password === formData.confirm_password){
                // const notifys = () => toast.error("All field are required");
                // setNotify(notifys);
                toast.error("All field are required",TOAST_CONFIG);
            }
            
        }
    }

    useEffect(() => {
        if (userSession) {
            const User = JSON.parse(userSession);
            getUser(User.id)
        }
        
    }, [userSession]);

    const getUser = (userValue)=> {
        const user_id = userValue;
        axios
        .get(`${BACKEND_URL}/api/V1/get-profile-information?user_id=${user_id}`)
        .then((response) => {
            if(response.data.status){
                setUserInformation(response.data.result.user);
                formData.first_name = response.data.result.user.first_name;
                formData.last_name = response.data.result.user.last_name;
                formData.email = response.data.result.user.email;
                formData.phone = response.data.result.user.phone;
            }
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });       
    }
    return (
        <Box className="accDetailContainer" sx={{ display: profileMenuTab == 1 ? "flex" : "none" }} >
            {/* <Box>
                <button onClick={notify}>Notify !</button>
                </Box> */}
            <Box className="individualPageContainer">
                <Box className="heading">
                    <Typography className="infoHeader">Personal Information</Typography>
                </Box>
                {editingSections[0] ? (
                    <>  
                        <form onSubmit={handleSubmit}>
                            <Box className="uName">
                                <TextField label="First Name" name="first_name" value={formData.first_name} onChange={handleChange} />
                                <TextField label="Last Name" name="last_name" value={formData.last_name} onChange={handleChange} />
                            </Box>
                            <Box className="uDOB">
                                <TextField label="Phone" name="phone" value={formData.phone} onChange={handleChange} />
                            </Box>
                            {/* Add other form fields here */}
                            <Button type="submit">Update</Button>
                        </form>
                        <Box className="cancelText" onClick={() => toggleEditComponent(0)}> Cancel </Box>
                    </>
                ) : (
                    <>
                        <Box className="pInfo">
                            <Typography className="infoText">{user.first_name} {user.last_name}</Typography>
                            <Typography className="infoText">{user.email}</Typography>
                        </Box>
                        <Box className="editText" onClick={() => toggleEditComponent(0)}>Edit</Box>
                    </>
                )}
            </Box>
            {/* Email Section */}
            <Box Box className="individualPageContainer">
                <Box className="heading">
                    <Typography className="infoHeader">Emai Address</Typography>
                </Box>
                {editingSections[1] ? (
                    <>
                        <form onSubmit={handleEmailSubmit}>
                            <Box className="uName">
                                <TextField label="Email Address" name="email" value={formData.email} onChange={handleChange}/>
                            </Box>
                            <Button type="submit">Update</Button>
                        </form>
                            <Box className="cancelText" onClick={() => toggleEditComponent(1)}>Cancel</Box>
                    </>
                ) : (
                    <>
                        <Box className="pInfo">
                            <Typography className="infoText">
                                {user.email}
                            </Typography>
                        </Box>
                        <Box className="editText" onClick={() => toggleEditComponent(1)}>Edit</Box>
                    </>
                )}
            </Box>

            {/* Email Section */}
            <Box Box className="individualPageContainer">
                <Box className="heading">
                    <Typography className="infoHeader">Password</Typography>
                </Box>
                {editingSections[2] ? (
                    <>
                        <form onSubmit={handlePasswordChangeSubmit}>
                            <Box className="uName">
                                <TextField label="Current Password" type='password' name="current_password" onChange={handleChange} />
                            </Box>
                            <Box className="uName">
                                <TextField label="New Password" type='password' name="new_password" onChange={handleChange} />
                            </Box>
                            <Box className="uName">
                                <TextField label="Confirm Password" type='password' name="confirm_password" onChange={handleChange} />
                                {
                                    (formData.password !== "") &
                                    (formData.confirm_password !== "") &
                                    (formData.password !== formData.confirm_password) &
                                    passwordSubmited ? (
                                        <p className="text-red-600">*Both password are not matched</p>
                                    ) : (
                                        ""
                                    )
                                }
                            </Box>
                            <Button type="submit">Update</Button>
                        </form>
                        <Box className="cancelText" onClick={() => toggleEditComponent(2)}>Cancel</Box>
                    </>
                ) : (
                <>
                    <Box className="pInfo">
                        <Typography className="infoText">●●●●●●●●●●●●</Typography>
                    </Box>
                    <Box className="editText" onClick={() => toggleEditComponent(2)}>Edit</Box>
                </>
                )}
            </Box>
        </Box>
    );
}
