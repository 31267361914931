import { Box, Typography } from '@mui/material';
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";


export const AppPrimaryBtn = ({ btnText, width, textColor, bgColor }) => {
    return (
        <Box className="appBtn" sx={{ background: bgColor, width, color: textColor }}>
            <Typography>{btnText}</Typography>
        </Box>
    )
}

export const UnderLBtn = ({ btnText, onClick, active, link, external = false }) => {
    const navigate = useNavigate();
    // return (
    //     <Box className={active ? "underSlideBtn underSlideBtnActve" : "underSlideBtn"} onClick={() => navigate(`${link}`)}>
    //         <Typography>{btnText}</Typography>
    //         <span></span>
    //     </Box>
    // )
    if (external) {
        return (
            <Box
                className={active ? "underSlideBtn underSlideBtnActve" : "underSlideBtn"}
                component="a"
                href={link}
                target="_blank"
                rel="noopener noreferrer" style={{ textDecoration: "none" }}
            >
                <Typography>{btnText}</Typography>
                <span></span>
            </Box>
        );
    }

    return (
        <Box
            className={active ? "underSlideBtn underSlideBtnActve" : "underSlideBtn"}
            onClick={() => navigate(`${link}`)}
        >
            <Typography>{btnText}</Typography>
            <span></span>
        </Box>
    );
}