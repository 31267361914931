export const getCountryCode = () => {
  const domain = window.location.hostname;
  const tld = domain.split(".").pop();
  const host = window.location.host;

  // switch (tld) {
  //   case 'com':
  //     return 101;
  //   case 'co.uk':
  //     return 231;
  //   case 'au':
  //     return 230;

  //   default:
  //     return 101;
  // }

  switch (host) {
    case "localhost:3000":
      return 230;
    case "localhost:3001":
      return 231;
    case "localhost:5173":
      return 101;

    default:
      return 231;
  }
};
