import React, { useEffect, useState } from 'react';
import "./style.css";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow, NgData } from "../../Assets/Data";
import MapComponent from "../../Components/Map";
import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';
import { getCountryCode } from '../../services/countryService';

export default function N25Global({ cookiePop, setCookiePop }) {
    const [check, setCheck] = useState(false);
    const [firstSection, setFirstSection] = useState([]);
    const [secondSection, setSecondSection] = useState([]);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const BACKEND_URL = config.BACKEND_URL;
        const countryCode = getCountryCode();
        axios
        .get(`${BACKEND_URL}/api/V1/n25-global/content`, {
            params: { country: countryCode }
        })
        .then((response) => {
            setFirstSection(response.data.result.first_section);
            setSecondSection(response.data.result.second_section);
            setContacts(response.data.result.contacts)
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="N25GlobalPage">
                {firstSection.map((item) => {
                    return(
                        <Box>
                            <Typography className='ngHeader' data-aos="fade-up">{item.title}</Typography>
                            <Typography className='ngNText' data-aos="fade-up">{item.content}</Typography>
                        </Box>
                    )
                })}
                <Box className="mapBox">
                    <MapComponent location={NgData} />
                </Box>
                {secondSection?.map((item) => {
                    return(
                        <Box>
                            <Typography className='nGCardsHeader'>{item.title}</Typography>
                        </Box>
                    )
                })}
                <Box className="ngCardBox">
                    {
                        contacts?.map((el, i) => (
                            <Box key={i} className="ngCard">
                                <Typography className='ngCardHeader'>{el.country_name}</Typography>
                                <Typography className='ngCardNText'>{el.address}</Typography>

                                <Box className="TextFlipBox">
                                    <Typography className='navItemTExt text1'>Email: {el.email}</Typography>
                                    <Typography className='navItemTExt text2'>Email: {el.email}</Typography>
                                </Box>

                                <Box className="TextFlipBox">
                                    <Typography className='navItemTExt text1'>Tel: {el.number}</Typography>
                                    <Typography className='navItemTExt text2'>Tel: {el.number}</Typography>
                                </Box>

                            </Box>
                        ))
                    }
                </Box>

                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {   whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                            <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>

            <Footer />
        </>
    )
}
