import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import config from "../../config";
import axios from "axios";
import './style.css';

//components
import RoundLogo from "../../Components/RoundLogo";
import CookiePop from "../../Components/CookiePop";
import { useNavigate } from "react-router-dom";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import EmailCommunity from "../../Components/EmailCommunity";
import { getCountryCode } from "../../services/countryService";
gsap.registerPlugin(ScrollTrigger);

export default function TheCaviar({ cookiePop, setCookiePop }) {
  
  const navigate = useNavigate();

  const BACKEND_URL = config.BACKEND_URL;
  const [slug] = useState("home");
  const [slug2] = useState("the-caviar");
  const [pages, setPages] = useState([]); // Define state for pages
  const [firstSection, setFirstSection] = useState([]);
  const [secondSection, setSecondSection] = useState([]);
  const [thirdSection, setThirdSection] = useState([]);
  const [products, setProducts] = useState([]);
  const [productContent, setProductContent] = useState([]);
  //   const [firstPart] = pageHeading.split(/(?=\binspires\b)/);

  useEffect(() => {
    const countryCode = getCountryCode();
    const BACKEND_URL = config.BACKEND_URL;
    axios
      .get(`${BACKEND_URL}/api/V1/the-caviar/products`, {
        params: { country: countryCode }
      })
      .then((response) => {
        console.log("The Caviar Page @@ = ",response.data.result.groupedProducts);
        const product2 = response.data.result.groupedProducts;
        // setPages(page);
        setProductContent(product2);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
  }, []);

  useEffect(() => {
    const countryCode = getCountryCode();
    // console.log("Country Code @@ =",countryCode);
    const BACKEND_URL = config.BACKEND_URL;
    axios
      .get(`${BACKEND_URL}/api/V1/page/${slug}`, {
        params: { country: countryCode }
      })
      .then((response) => {
        // const { page } = response.data.result;
        const product = response.data.result.products;
        // setPages(page);
        setProducts(product);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
  }, [slug]);

  useEffect(() => {
    const countryCode = getCountryCode();
      axios
      .get(`${BACKEND_URL}/api/V1/the-caviar/content/${slug2}`, {
        params: { country: countryCode }
    })
      .then((response) => {
        const { page } = response.data.result;
        setPages(page);
      })
      .catch((error) => {
          console.error("Error fetching layout data:", error);
      });
  }, []);

  useEffect(() => {
    // First section
    const sectionOne = pages?.filter(
      (page) => page.section === "First Section"
    );
    setFirstSection(sectionOne);

    // Second section
    const sectionSecond = pages?.filter(
      (page) => page.section === "Second Section"
    );
    setSecondSection(sectionSecond);

    // Third section
    const sectionThird = pages?.filter(
      (page) => page.section === "Third Section"
    );
    setThirdSection(sectionThird);

  }, [pages]);

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setVh();
    window.addEventListener("resize", setVh);
    return () => window.removeEventListener("resize", setVh);
  }, []);


  return (
    <>
      <RoundLogo />
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />
      <Box className="discoverySection theCaviarSectionMain"></Box>
      {/* discover Section */}
      {firstSection?.map((item) => {
    
        return (
          <Box className="discoverySection theCaviarSectionFirst">
            <Box className="theCaviarFirstSection" style={{ display: 'flex', width: '100%' }}>
                <Box style={{ width: '50%', paddingRight: '20px' }}>
                    <Typography style={{ fontSize: "21px", fontWeight: "400", lineHeight: "1.2" }} data-aos="fade-up">
                        {item?.title}
                    </Typography>
                </Box>
                <Box style={{ width: '50%', paddingLeft: '20px' }}>
                    <Typography  style={{ fontSize: "14px"}} data-aos="fade-up">
                    {item?.content}
                    </Typography>
                </Box>
            </Box>
          </Box>
        );
      })}
      {productContent && Object.keys(productContent).length > 0 && Object.keys(productContent).map((key) => {
        const item = productContent[key];
        return (
          <Box key={key} className="shopSection">
            <Box className="b1"></Box>
            <Box className="b2">
              <Box className="shopTextBox">
                
                <Typography className="shopTBHeader" data-aos="fade-up">
                  {item?.page?.title || 'No Title Available'}
                </Typography>
                <Box className="shopSecondTextBox">
                  <Typography data-aos="fade-up">
                    {item?.page?.content || 'No Content Available'}
                  </Typography>
                  
                  {item?.page?.button_link && (
                    <Box
                      className="shopSLabelTextBox"
                      data-aos="fade-up"
                      onClick={() => navigate(`${item.page.button_link}`)}
                    >
                      <span>{item.page.button_label}</span>
                      <span className="ssltbLine"></span>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Display products for this type_id */}
              <Box sx={{justifyContent:"normal"}} className="threeTBox">
                {item?.products && item.products.length > 0 ? (
                  item.products.map((product) => (
                    <Box
                      key={product.id}
                      className="TTItem"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      <Box className="imageFBox">
                        <img
                          src={`${config.BACKEND_URL}${product.prod_image}`}
                          className="image1"
                          alt={product.prod_name}
                        />
                        <img
                          src={`${config.BACKEND_URL}${product.prod_cap_image}`}
                          className="image2"
                          alt={product.prod_name}
                        />
                        <Typography>
                          {product.prod_name} <br />
                          from £{product.price}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  ""// <Typography>No products available for this type</Typography>
                )}
              </Box>
            </Box>
            <Box className="b3"></Box>
          </Box>
        );
      })}
      {/* meticulous Section */}
     
          {/* <Box className="shopSection">
            <Box className="b1">
                
            </Box>
            <Box className="b2">
              <Box className="shopTextBox">
                <Typography className="shopTBHeader" data-aos="fade-up">
                Classic - the traditional varieties of caviar
                </Typography>
                <Box className="shopSecondTextBox">
                  <Typography data-aos="fade-up">The origins of caviar and how it became a luxury delicacy we know today all started with the traditional Caspian Sea varieties. They are distinguished by their softer velvety textures, smaller grains but powerful flavour with notes of the ocean breeze. Our interpretation of the "classics" is more refined and lower on the salt profile so you can enjoy the differences of the varieties.</Typography>
                  <Box
                    className="shopSLabelTextBox"
                    data-aos="fade-up"
                    
                  >
                    <span> our caviar </span>
                    <span className="ssltbLine"></span>
                  </Box>
                </Box>
              </Box>

              <Box mt={4} className="threeTBox">
                {products.map((product) => {
                  return (
                    <Box
                      className="TTItem"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      <Box className="imageFBox">
                      <img src={`${BACKEND_URL}${product.prod_image}`} className='image1' />
                      <img src={`${BACKEND_URL}${product.prod_cap_image}`} className='image2' />
                      
                        <Typography>
                          {product.prod_name} <br />
                          from £{product.price}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                
              </Box>
            </Box>
            <Box className="b3"></Box>
          </Box> */}
     

{/* {thirdSection.map((item) => {
        return (
          <Box className="shopSection">
            <Box className="b1">
                
            </Box>
            <Box className="b2">
              <Box className="shopTextBox">
                <Typography className="shopTBHeader" data-aos="fade-up">
                {item?.title}
                </Typography>
                <Box className="shopSecondTextBox">
                  <Typography data-aos="fade-up">{item?.content}</Typography>
                  <Box
                    className="shopSLabelTextBox"
                    data-aos="fade-up"
                    onClick={() => navigate(`${item.button_link}`)}
                  >
                    <span> Unser Kaviar </span>
                    <span className="ssltbLine"></span>
                  </Box>
                </Box>
              </Box>

              <Box className="threeTBox">
                {products.map((product) => {
                  return (
                    <Box
                      className="TTItem"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      <Box className="imageFBox">
                      <img src={`${BACKEND_URL}${product.prod_image}`} className='image1' />
                      <img src={`${BACKEND_URL}${product.prod_cap_image}`} className='image2' />
                      
                        <Typography>
                          {product.prod_name} <br />
                          from £{product.price}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                
              </Box>
            </Box>
            <Box className="b3"></Box>
          </Box>
        );
      })} */}
      
      {/* mail section */}
      
      <EmailCommunity />
      <Footer />
    </>
  );
}
